<template>
  <div>
    <h5>Weeks</h5>
    <div class="row" v-if="! loading">
      <div
        class="col-md-4"
        v-for="(week, i) in weeks"
        :key="`week-${i}`"
      >
        <div class="card">
          <router-link :to="{ name: 'moderator.academy.weeks.show', params: { week_id: week.id }}">
            <video-thumbnail :video="week.thumbnail" />
          </router-link>
          <div class="card-body">
            <h6>
              <router-link :to="{ name: 'moderator.academy.weeks.show', params: { week_id: week.id }}"
                ><strong
                  >#{{ week.sort }} {{ week.title }}</strong
                ></router-link
              >
            </h6>
            <div class="row text-center">
              <div class="col-12">
                <span class="d-block">Videos</span>
                <span>{{ week.videos }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="weeks.length == 0">
        <div class="alert alert-warning">There is no data to display</div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      weeks: []
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/moderator/academy/${this.$route.params.course_id}/weeks`).then(response => {
        this.weeks = response.data.weeks
        this.loading = false
      })
    }
  }
}
</script>