<template>
  <div>
    <div class="row" v-if="!loading_menu">
      <div class="col-md-12">
        <router-link :to="{ name: 'moderator.academy.show' }"><i class="lni lni-arrow-left"></i> Back To Sections</router-link>
        <h4>Section #{{ section.sort }} - {{ section.title }}</h4>
        <hr>
      </div>
      <div class="col-3">
        <h5 class="text-muted">Courses</h5>
        <ul class="academy-nav">
          <li v-for="(course, i) in courses" :key="`course-${i}`">
            <router-link
              :to="{ name: 'moderator.academy.courses.show', params: { course_id: course.id } }"
              :class="course.id == current.id ? 'link-active' : ''"
              >#{{ course.sort }} {{ course.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col-9">
        <div v-if="!loading_content">
          <h4>Course #{{ current.sort }} {{ current.title }}</h4>
          <p>
            <span class="mr-4"
              ><strong>Weeks:</strong> {{ current.weeks }}</span
            >
            <span><strong>Videos:</strong> {{ current.videos }}</span>
          </p>
          <div v-html="current.about"></div>
          <hr />
          <week-list />
        </div>
        <loading v-if="loading_content" />
      </div>
    </div>
    <loading v-if="loading_menu" />
  </div>
</template>

<script>
import WeekList from "./weeks/Index";

export default {
  components: {
    WeekList,
  },

  mounted() {
    this.fetchMenu();
    this.fetchTitle()
  },

  data() {
    return {
      loading_menu: true,
      loading_content: true,
      courses: [],
      current: {},
      section: {}
    };
  },

  methods: {
    fetchMenu() {
      this.loading_menu = true;

      this.$axios.get(`/api/v1/moderator/academy/${this.$route.params.id}/courses`).then(response => {
        this.courses = response.data.courses;
        this.loading_menu = false;
        this.fetchContent()
      })
    },

    fetchTitle() {
      this.$axios
        .get(`/api/v1/moderator/academy/sections/${this.$route.params.id}`)
        .then((response) => {
          this.section = response.data.section;
        });
    },

    fetchContent() {
      this.loading_content = true;

      this.$axios
        .get(`/api/v1/moderator/academy/${this.$route.params.id}/courses/${this.$route.params.course_id}`)
        .then((response) => {
          this.current = response.data.course;
          this.loading_content = false;
        });
    },
  },

  computed: {
    course_id() {
      return this.$route.params.course_id;
    },
  },

  watch: {
    course_id() {
      this.fetchContent();
    },
  },
};
</script>
